import { DatePipe } from '@angular/common';
import {
    AfterViewInit,
    Component,
    Inject,
    OnInit,
    ViewChild,
    ViewChildren,
    QueryList,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PreBoardingService } from 'src/app/shared/pre-boarding.service';
import { AuthenticationService } from 'src/app/shared/authentication/authentication.service';
import { environment } from 'src/environments/environment';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import moment from "moment";
import { saveAs } from 'file-saver';
import { SwaggerUIBundle, SwaggerUIStandalonePreset } from 'swagger-ui-dist';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
    candidateCreateForm: FormGroup;
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = [
        'name',
        'emailId',
        'validUpto',
        'emailFlag',
        'id',
        'moreOptions',
    ];
    totalRecords: any;
    checked: boolean = true;
    maxDob: any;
    hiddenTrue: boolean = true;
    candidateID: string;
    datePipe = new DatePipe('en-US');
    candidateInformation: any;
    candidateInfo = [];
    userDeatils: any;
    candidateInfoInProgress = [];
    candidateInfoProcessed = [];
    candidateInfoJoined = [];
    candidateInfoDeclined = [];
    candidateInfoLength: any;
    candidateInfoInProgressLength: any;
    allCandidates: any;
    dialogRef: any;
    token: any;
    showMore = false;
    inProgressData = false;
    SpinnerService: any;
    load: boolean = false;
    showApi = false;
    originURL: string;
    @ViewChild(MatSort)
    set sort(value: MatSort) {
        this.dataSource.sort = value;
    }
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChildren('testUi') private testUi: QueryList<HTMLDivElement>;

    constructor(

        private fb: FormBuilder,
        private preBoardingService: PreBoardingService,
        private _router: Router,
        public dialog: MatDialog,
        private authenticationService: AuthenticationService,

    ) {
        this.candidateCreateForm = this.fb.group({
            fullName: new FormControl('', [
                Validators.required,
                Validators.maxLength(250),
                Validators.pattern('^[a-zA-Z ]*$'),
            ]),
            emailAddress: new FormControl('', [
                Validators.required,
                Validators.email,
            ]),
            contactNumber: new FormControl('', [Validators.required,]),
            employeeType: new FormControl('', [Validators.required,]),
            expectedDOJ: new FormControl('', [Validators.required]),
            emailCheckbox: new FormControl(true, [Validators.required]),
        });
    }

    ngOnInit(): void {
        let url=environment.apiUrl;

        this.inProgressData = false;
        this.maxDob = new Date();
        this.showApi = false;
        this.dataSource = new MatTableDataSource([]);
        this.originURL = new URL(url).origin;
        this.authenticationService.getToken().then(data => {
            this.token = data;
        });
        this.authenticationService.getLoggedUser().then(data => {
            this.userDeatils = data;
            if (this.userDeatils.attributes.candidateId) {
                sessionStorage.setItem('candidateID', this.userDeatils.attributes.candidateId[0])
            }
            // this.userDeatils.attributes.candidateId ? sessionStorage.setItem('candidateID', this.userDeatils.attributes.candidateId[0]) : '';
        });
        this.getCandidateList();
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    moreClicked(text) {
        this.inProgressData = false;
        if (text === 'candidateInfo') {
            const dialogRef = this.dialog.open(CandidateStatusComponent, {
                width: '100%',
                // height: '30%',
                data: this.candidateInfo,
                disableClose: true
            });
        } else if (text === 'candidateInfoInProgress') {
            const dialogRef = this.dialog.open(CandidateStatusComponent, {
                width: '100%',
                // height: '30rem',
                data: this.candidateInfoInProgress,
                disableClose: true
            });
        } else if (text === 'candidateInfoJoined') {
            const dialogRef = this.dialog.open(CandidateStatusComponent, {
                width: '100%',
                // height: '30rem',
                data: this.candidateInfoJoined,
                disableClose: true
            });
        } else if (text === 'candidateInfoDeclined') {
            const dialogRef = this.dialog.open(CandidateStatusComponent, {
                width: '100%',
                // height: '30rem',
                data: this.candidateInfoDeclined,
                disableClose: true
            });
        } else if (text === 'candidateInfoProcessed') {
            const dialogRef = this.dialog.open(CandidateStatusComponent, {
                width: '100%',
                // height: '30rem',
                data: this.candidateInfoProcessed,
                disableClose: true
            });
        } else if (text==='apiCalled') {
            // $(function() {
            this.showApi = true;
            this.dataSource.paginator=this.paginator;
            this.testUi.changes.subscribe((comps1: QueryList<HTMLDivElement>) => {
                SwaggerUIBundle({
                    urls: [
                        {
                            name: 'Sample',
                            url: `${ this.originURL }/v2/api-docs`,//json
                        },
                    ],
                    /* @ts-ignore */
                    domNode: comps1.first.nativeElement,
                    // dom_id: '#test_ui',
                    // domNode: document.getElementById('test_ui'),
                    deepLinking: true,
                    requestInterceptor: (req) => {
                        req.headers.Authorization = `Bearer ${this.token}`
                        return req
                    },
                    presets: [
                        SwaggerUIBundle.presets.apis,
                        SwaggerUIStandalonePreset
                    ],
                    layout: 'StandaloneLayout',
                    // supportedSubmitMethods: ['get', 'post', 'put', 'delete', 'patch'],
                });
            });

        }
        else if (text==='dashboard') {
            this.showApi=false;
        }
    }
    reportsClicked() {
        this._router.navigate(["reports"]);
    }

    candidatereportsClicked() {
        this._router.navigate(["candidatesreports"]);
    }

    bgvStatusReports(action) {
        if (action === 'overall') {
            this.preBoardingService.exportBGVStatusReport().subscribe(data=> {
                saveAs(data, 'BGV_Overall_Status'.replace(/\s+/g, ''));
            });
        } else if (action === 'insufficiency') {
            this.preBoardingService.exportInsufficiencyReport().subscribe(data=> {
                saveAs(data, 'BGV_Insufficiency_Report'.replace(/\s+/g, ''));
            });
        }
    }
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    getStarted(candidate) {
        this._router.navigateByUrl('/emp-data', { state: candidate });
    }

    getCandidateList() {
        this.load = true,
            this.preBoardingService.getCandidateList().subscribe((res) => {
                this.allCandidates = res;
                this.load = false;
                this.candidateInfo = res.PENDING;

                if (this.candidateInfo) {
                    this.candidateInfo.forEach(candidate => {
                        let date = moment(candidate.accountCreatedDate).format('DD MMM YYYY');
                        let restrictedDate = moment().subtract(1, 'days');
                    });
                }
                this.candidateInfoLength = res.PENDING ? res.PENDING.length : 0;
                if (res.INPROGRESS) {
                    const sortedInProgress = res.INPROGRESS.sort(function (a, b) {
                        return a.candidateId - b.candidateId;
                    });
                }

                this.candidateInfoInProgress = res.INPROGRESS;
                this.candidateInfoInProgressLength = res.INPROGRESS ? res.INPROGRESS.length : 0;
                this.candidateInfoProcessed = res.PROCESSED;
                this.candidateInfoJoined = res.JOINED;
                // this.candidateInfoDeclined = res.DECLINED;
            });
    }

    openUpdate(candidate) {
        const dialogRef = this.dialog.open(EditCandidateComponent, {
            width: '900px',
            height: '450px',
            data: candidate

        });
        dialogRef.afterClosed().subscribe((result) => {
            this.getCandidateList();
        });
    }

    openUpdateDialogBox(row) {
        const dialogRef = this.dialog.open(EditDashBoardDetailsComponent, {
            width: '900px',
            height: '450px',
            disableClose: true
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.getCandidateList();
        });
    }
    deleteCandidateDialogBox(candidates) {
        const dialogRef = this.dialog.open(DeleteCandidateComponent, {
            width: '30rem',
            height: '12rem',
            data: candidates
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.getCandidateList();
        });
        //this.ngOnInit();
    }

    lettersOnly(event) {
        var charCode = event.keyCode;
        if (
            (charCode > 64 && charCode < 91) ||
            (charCode > 96 && charCode < 123) ||
            charCode == 8 ||
            charCode === 32
        )
            return true;
        else return false;
    }
    omit_special_char(event) {
        var k;
        k = event.charCode;
        return (
            (k > 64 && k < 91) ||
            (k > 96 && k < 123) ||
            k == 8 ||
            k == 32 ||
            (k >= 48 && k <= 57)
        );
    }
    getCandidate(candidate) {
        let authorities = this.authenticationService.authorities;
		if (authorities) {
            authorities.forEach((element) => {
				if (element==="NAVIGATE_CANDIDATE_INFO") {
                    sessionStorage.setItem('candidateID', candidate.candidateId);
                    this._router.navigateByUrl('/emp-data', { state: candidate });
                }
            });
        }
    }

}


//Create candidate component --> Component to be renamed.
@Component({
    selector: 'edit-dashboard-details',
    templateUrl: 'edit-dashboard-details.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class EditDashBoardDetailsComponent implements OnInit {
    candidateDetailsForm: FormGroup;
    datePipe = new DatePipe('en-US');
    clicked = false;
    candidateInfo=[];
    candidateInfoInProgress=[];
    currentDate: Date;
    futureDate: Date;
    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<EditDashBoardDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private preBoardingService: PreBoardingService,

    ) {
        this.candidateDetailsForm = this.fb.group({
            fullName: new FormControl('', [Validators.required, Validators.maxLength(250)]),
            emailAddress: new FormControl('', [Validators.email, Validators.required]),
            contactNumber: new FormControl("", [Validators.required, Validators.minLength(10), Validators.maxLength(10),]),
            expectedDOJ: new FormControl('', [Validators.required]),
            employeeType: new FormControl('', [Validators.required]),
            emailCheckbox: new FormControl(true),
            accountCreatedDate: new FormControl(''),
            canID: new FormControl(''),
        });

    }
    validateNumber(event) {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
    lettersOnly(event) {
        var charCode = event.keyCode;
        if (
            (charCode > 64 && charCode < 91) ||
            (charCode > 96 && charCode < 123) ||
            charCode == 8 ||
            charCode === 32
        )
            return true;
        else return false;
    }
    ngOnInit() {
        const currentYear = new Date().getFullYear();
        this.futureDate = new Date(currentYear + 1,11,31);
        this.currentDate = new Date();
    }
	createCandidate() {
        this.clicked = true;
		setTimeout(()=>this.clicked = false,4000);
        this.preBoardingService
            .createCandidate(this.candidateDetailsForm.value)
            .subscribe((res) => {
                this.candidateDetailsForm.reset();
                this.dialogRef.close();
                this.candidateDetailsForm.get('fullName').setErrors(null);
                this.candidateDetailsForm.get('emailAddress').setErrors(null);
                this.candidateDetailsForm.get('contactNumber').setErrors(null)
                this.candidateDetailsForm.get('employeeType').setErrors(null)
                this.candidateDetailsForm.get('expectedDOJ').setErrors(null);
                this.candidateDetailsForm.get('emailCheckbox').setValue(true);
            });
        this.getCandidateList();
    }
    getCandidateList() {
        this.preBoardingService.getCandidateList().subscribe((res) => {
            this.candidateInfo = res.PENDING;
            this.candidateInfoInProgress = res.INPROGRESS;

        });
    }
    closeDialogBox() {
        this.dialogRef.close();
    }
    openDialog(): void {
        this.dialogRef.close();

    }
    onNoClick(): void {
        this.dialogRef.close();
    }
}

// Edit Candidate Component
@Component({
    selector: 'edit-candidate',
    templateUrl: 'edit-candidate.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class EditCandidateComponent implements OnInit {
    isemailreadonly: boolean = false;
    updateDetailsForm: FormGroup;
    minDate: any;
    datePipe = new DatePipe('en-US');
    candidateInfo: any;
    updatedList: any;
    dateRange: any;
    alive = true;
    unsubscribeAll$: any;
    futureDate: Date;
    currentDate: Date;
    // isDisabled:boolean=true;
    constructor(private fb: FormBuilder,
        public dialogRef: MatDialogRef<EditCandidateComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private preBoardingService: PreBoardingService,){
			this.updateDetailsForm = this.fb.group({
				fullName: new FormControl('', [
					Validators.required,
					Validators.maxLength(250),
				]),
				emailAddress: new FormControl('', [
					Validators.required,
					Validators.email,
				]),
                contactNumber: new FormControl('', [Validators.required]),
                employeeType: new FormControl('', [Validators.required]),
				expectedDOJ: new FormControl('', [Validators.required]),
				emailFlag: new FormControl(true),
                accountCreatedDate: new FormControl(''),
				canID: new FormControl(''),
                joiningStatus: new FormControl(''),
                userId: new FormControl(''),
                password: new FormControl(''),
			});
		}
	ngOnInit(){
		const currentYear = new Date().getFullYear();
        this.futureDate = new Date(currentYear + 1,11,31);
        this.currentDate = new Date();
        this.updateDetailsForm.patchValue({
            fullName: this.data.name,
            emailAddress: this.data.emailId,
            contactNumber: this.data.contactNumber,
            employeeType: this.data.employeeType,
            expectedDOJ: this.datePipe.transform(this.data.validUpto, 'yyyy-MM-dd'),
            emailFlag: this.data.emailFlag,
            canID: this.data.candidateId,
            joiningStatus: this.data.joiningStatus,
            userId: this.data.userId,
            password: this.data.password,
            accountCreatedDate: this.data.accountCreatedDate
        });
        if (this.data.employeeType === "DC") {
            this.updateDetailsForm.get("employeeType").setValue("DC");
        } else if (this.data.employeeType === "INTERN") {
            this.updateDetailsForm.get("employeeType").setValue("INTERN");
        } else if (this.data.employeeType === "VC") {
            this.updateDetailsForm.get("employeeType").setValue("VC");
        } else if (this.data.employeeType === "FTE") {
            this.updateDetailsForm.get("employeeType").setValue("FTE");
        }
        if (this.data.joiningStatus == "INPROGRESS") {
            this.isemailreadonly = true
        } else {
            this.isemailreadonly = false
        }
    }

    lettersOnly(event) {
        var charCode = event.keyCode;
        if (
            (charCode > 64 && charCode < 91) ||
            (charCode > 96 && charCode < 123) ||
            charCode == 8 ||
            charCode === 32
        )
            return true;
        else return false;
    }

    validateNumber(event) {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
    updateDetails(id) {
        this.preBoardingService
            .updateCandidateDashboard(this.updateDetailsForm.value)
            .subscribe((res) => {
                this.closeDialogBox();
        });
    }

    closeDialogBox() {
        this.dialogRef.close();
    }
    onNoClick(): void {
        this.dialogRef.close();
    }
    ngOnDestroy() {
        this.alive = false;
    }
}
@Component({
    selector: 'delete-candidate',
    templateUrl: 'delete-candidate.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DeleteCandidateComponent implements OnInit {
    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<DeleteCandidateComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private preBoardingService: PreBoardingService
    ) { }
    ngOnInit() {
    }
    closeDialogBox() {
        this.dialogRef.close();
    }
    deleteCandidate() {
        this.preBoardingService
            .deleteCandidate(this.data.candidateId)
            .subscribe((res) => {
            });
        this.dialogRef.close();
    }
}

@Component({
    selector: 'app-candidate-status',
    templateUrl: 'candidates-status-table.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class CandidateStatusComponent implements OnInit {
    candidatesJoiningDataSource = new MatTableDataSource([]);
    candidatesDisplayedColumns: string[] = [
        'srNo', 'name', 'validUpto', 'emailId', 'options'
    ];
    buttonedit: boolean;

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<CandidateStatusComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private preBoardingService: PreBoardingService,
        public dashboardComponent: DashboardComponent) {

    }
    ngOnInit() {
        this.candidatesJoiningDataSource = new MatTableDataSource(this.data);
    }
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.candidatesJoiningDataSource.filter = filterValue.trim().toLowerCase();
    }
    editdata(candidate) {
        // this.buttonedit=false;
        this.dialogRef.close();
        this.dashboardComponent.openUpdate(candidate);
        // this.buttonedit=true;
    }
    deletedata(candidate) {
        this.dialogRef.close();
        this.dashboardComponent.deleteCandidateDialogBox(candidate);
    }
    getCandidate(candidate) {
        this.dialogRef.close();
        this.dashboardComponent.getCandidate(candidate);
    }
    closeDialog() {
        this.dialogRef.close();
        document.querySelectorAll('.step_name').forEach(n => n.classList.remove('step-focus'));
        let step = document.getElementById("dashboard");
        step.classList.add("step-focus");
    }
    onNoClick(): void {
        this.dialogRef.close();
    }
}