import { ChangeDetectorRef, Component, Inject, OnInit, ElementRef, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/authentication/authentication.service';
import { PreBoardingService } from './../../../shared/pre-boarding.service';
import $ from 'jquery';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Dimensions, ImageCroppedEvent } from 'ngx-image-cropper';
import { environment } from 'src/environments/environment';
import {salt, encIV } from '../../../app-constants';
import * as CryptoJS from 'crypto-js';
declare global {
	interface String {
        initCap(): string;
    }
}
@Component({
	selector: 'app-main-layout',
	templateUrl: './main-layout.component.html',
	styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, AfterContentChecked {
	basicInfo = false;
	completion = false;
	progressbarValue = 0;
	count: number;
	isLinear = false;
	candidateID: any;
	candidateName: any;
	collapsed = false;
	router: Router;
	userDeatils: any;
	candidateInfo: any;
	resultPO: any;
	widowPensionLength: any;
	epsLength: any;
	epfLength: any;
	qualification: any;
	resultsDocStatus: any;
	setProfilePic: any;
	empType: any;
	subscription: any;
	candidateZipFileDetails:any;
	apertureEnvRedirectUri: string;
	finalApproved:boolean=false;
	dataChecked: boolean = false;
	partiallyApproved:boolean=false;
	displayOnlyHRRejectdApprovedDocs: boolean=false;
	conditionallyApproved: boolean=false;
	personalEmployeeType: any;
	allData: any;
	allEmployeeTypes: any;
	noDocsSubmitted: boolean = true;
	showBGVOption: boolean = false;
	provideConsent: any;
	constructor(private preBoardingService: PreBoardingService,
		public authenticationService: AuthenticationService,
		private cdr: ChangeDetectorRef,
		public dialog: MatDialog,
		private _router: Router) {
		this.router = _router;
	}

	ngOnInit (): void {
		// console.log(this.empDataComponent.candidateID);
		// this.candidateID=sessionStorage.getItem('candidateID');
		this.subscription=this.preBoardingService.newEmpType.subscribe(employeeType => {
			this.empType=employeeType;
		});
		this.preBoardingService.count.subscribe(c => {
			this.count = c;
		});
		this.preBoardingService.getServiceName('Main-Layout Component');
		this.userDeatils=this.authenticationService.userDetails;
		this.userDeatils ? this.dataChecked = true : this.dataChecked = false;
		this.getAuthorities();
		if (this.candidateID !== undefined) {
			this.preBoardingService.getpersonalInfo(this.candidateID).subscribe(res => {
				this.allData=res;
				this.candidateZipFileDetails=res.candidateInformation;
				this.personalEmployeeType=res.employeeType;
				if (this.candidateZipFileDetails.documentUploadStatus=== null){
					this.noDocsSubmitted = false;
				}
				if (this.candidateZipFileDetails.documentUploadStatus=== "APPROVEDINCONDTION") {
					this.conditionallyApproved=true;
					this.finalApproved=false;
					this.partiallyApproved=false;
				} else if (this.candidateZipFileDetails.documentUploadStatus==='APPROVED') {
					this.finalApproved=true;
					this.conditionallyApproved=false;
					this.partiallyApproved=false;
				} else if (this.candidateZipFileDetails.documentUploadStatus==='SUBMITTED'&&this.candidateZipFileDetails.zipFileUrl!==null&&this.candidateZipFileDetails.approvedZipFileUrl!==null) {
					this.partiallyApproved=true;
					this.finalApproved=false;
					this.conditionallyApproved=false;
				} else if (this.candidateZipFileDetails.documentUploadStatus==='SUBMITTED'&&this.candidateZipFileDetails.zipFileUrl===null&&this.candidateZipFileDetails.approvedZipFileUrl===null) {
					this.finalApproved=false;
					this.conditionallyApproved=false;
					this.partiallyApproved=false;
				} else if (this.candidateZipFileDetails.documentUploadStatus==='REJECTED') {
					this.finalApproved=false;
					this.conditionallyApproved=false;
					this.partiallyApproved=false;
				}
			});
			this.preBoardingService.getConsent(this.candidateID).subscribe(response=> {
				this.provideConsent = response;
			  });
			let a = this.preBoardingService.changeEmpType(this.personalEmployeeType);
			this.preBoardingService.candidateProgressStatus(this.candidateID).subscribe(res => {
				if (this.personalEmployeeType==='DC'  ) {
					this.preBoardingService.getDCProgress(res);
				} else if (this.personalEmployeeType==='INTERN') {
					this.preBoardingService.getINTERNProgress(res);
				} else if (this.personalEmployeeType==='FTE') {
					this.preBoardingService.getProgress(res);
				} else if (this.personalEmployeeType==='VC') {
					this.preBoardingService.getVCProgress(res);
				}
			});
			this.getBGVLinkStatus();
		}
		this.apertureEnvRedirectUri=environment.apertureUri;
	}

	getBGVLinkStatus() {
		this.preBoardingService.getBGVStatusLink(this.candidateID).subscribe(response=> {
			if ( response=== true) {
				this.showBGVOption = true;
			} else if (response === false) {
				this.showBGVOption = false;
			}
		});
	}

	ngAfterContentChecked(): void {
		this.cdr.detectChanges();
	}
	goToDashboards () {
		this._router.navigate(['dashboard']);
	}
	getAuthorities () {
		let authorities = this.authenticationService.authorities;
		if (authorities) {
			authorities.forEach((element) => {
				if (element==="DASHBOARD_VIEW_CANDIDATE") {
					this.candidateID = sessionStorage.getItem('candidateID');
					this.getUserProfileImage(this.candidateID);
					let professionalReferences=[];
					this.preBoardingService.finalCompletion.subscribe(value => {
						this.completion = value;
					});
					if (this.preBoardingService.experienceComplete) {
						$('#circle-0-1').attr('style', 'background-color: #6CB543 !important; color: white !important');
						this.preBoardingService.personalInfoComplete = true;
					}
					if (this.preBoardingService.epfComplete) {
						$('#circle-0-2').attr('style', 'background-color: #6CB543 !important; color: white !important');
					}
					if (this.preBoardingService.nomineeComplete) {
						$('#circle-0-3').attr('style', 'background-color: #6CB543 !important; color: white !important');
					}
					if (this.preBoardingService.policeComplete) {
						$('#circle-0-4').attr('style', 'background-color: #6CB543 !important; color: white !important');
					}
					if (this.preBoardingService.documentsComplete) {
						$('#circle-0-5').attr('style', 'background-color: #6CB543 !important; color: white !important');
					}
				}
				if (element==='DASHBOARD_VIEW_HR' &&this.router.url === '/dashboard') {
					sessionStorage.setItem('candidateID', null);
					this.candidateID=sessionStorage.getItem('candidateID');
				} else if (element==='DASHBOARD_VIEW_HR'&&this.router.url!=='/dashboard') {
					this.candidateID=sessionStorage.getItem('candidateID');
				}
			});
		}
	}

	getUserProfileImage(candidateId) {
		let normalResponse = ""
		this.preBoardingService.retrieveProfilePicture(candidateId).subscribe(res => {
			if (res !== null) {
				this.setProfilePic = "data:" + res.data;
			} else this.setProfilePic = null;
		});

	}
	nextCount() {
		this.preBoardingService.nextCount();
	}
	empInfoClicked() {
		this._router.navigate(['emp-data']);
	}
	hrDocsClicked() {
		this._router.navigate(['hr-documents']);
	}
	docsClicked () {
		this._router.navigate(['documents']);
	}
	BGVClicked() {
		this._router.navigate(['bgv']);
	}
	sendRequest () {
		String.prototype.initCap = function() {
            return this.toLowerCase().replace(/(?:^|\s)[a-z]/g, function(m) {
                return m.toUpperCase();
            });
        };
		let removedSpaces=this.candidateZipFileDetails?.name.trim().initCap();
		let url=`${this.apertureEnvRedirectUri}/resource/add-resource/?candidateId=`+this.candidateID+'&'+'name='+removedSpaces+'&'+'gender='+this.candidateZipFileDetails.gender;
		window.open(url,'_blank');
	  }
	openProfilePicture() {
		const dialogRef = this.dialog.open(EditProfilePictureComponent, {
			width: '30rem',
			height: '20rem',
			data: this.setProfilePic
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.setProfilePic = result.data;
			}
		});
	}

	navigateTo(formType) {
		switch (formType) {
			case 'empInfoForm': {
				this._router.navigate(['emp-data']);
				break;
			}
		}
	}
	logout() {
		// this.authenticationService.logout();
		location.href = environment.keycloackUrl + '/realms/preboarding/protocol/openid-connect/logout';
	}

}

@Component({
	selector: 'edit-profile-picture',
	templateUrl: 'edit-profile-picture.html',
	styleUrls: ['./main-layout.component.scss'],
})
export class EditProfilePictureComponent implements OnInit {
	candidateID: string;
	specialCharsFound: boolean =false;
	constructor(public dialogRef: MatDialogRef<EditProfilePictureComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,
		private modalService: NgbModal,
		private preBoardingService: PreBoardingService, public el: ElementRef) {
		this.el.nativeElement
	}
	imageUrl: any;
	imageSizeFlag = false;
	imageLengthFlag = false;
	imageData: any = {};
	imageTypeFlag = false;
	imageChangedEvent: any = '';
	imageLabel = 'Update Profile Picture';
	imageUrlCopy: any;
	modalReference: NgbModalRef;
	profilePreview = false;
	imgChangeEvt: any = '';
	cropImgPreview: any = '';
	showImageCropper: boolean = false;
	showCropper = false;
	cropSaved: boolean = false;
	ngOnInit() {
		this.cropImgPreview = this.data;
		this.candidateID = sessionStorage.getItem('candidateID');
		this.cropSaved = false;
	}
	imageCropped(image: string) {
		this.imageData.data = image;
		this.imageUrl = image;
		this.imageLabel = 'Change Profile Picture';
	}
	encrypt(data) {
        if (typeof data === 'string') {
            const ciphertext = CryptoJS.AES.encrypt(data, salt, {
                iv: encIV
            });
            const replacedCipher = ciphertext.toString().replace(/\//g, 'ForwardSlash').replace(/\+/g, 'PlusSign');
            return replacedCipher;
        } else {
            const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), salt, {
                iv: encIV
            });
            const replacedCipher = ciphertext.toString().replace(/\//g, 'ForwardSlash').replace(/\+/g, 'PlusSign');
            return replacedCipher;
        }
        
      }

	fileChangeEvent(event: any, content): void {
		const file: File = event.target.files[0];
		let validFilenameRegex = /^[a-zA-Z0-9]*$/;
        let textMatched = validFilenameRegex.test(file.name.split('.')[0]);
		if(!textMatched){
			this.specialCharsFound =true;
			return;
		}
		if (file.size >= 250000) {
			this.imageSizeFlag = true;
			return;
		} else {
			this.imageSizeFlag = false;
		}
		if (file.name.length >= 100) {
			this.imageLengthFlag = true;
			return;
		} else {
			this.imageLengthFlag = false;
		}
		this.imageData.filename = this.encrypt(file.name);
		// const myReader: FileReader = new FileReader();
		// myReader.onloadend = e => {
		// 	this.imageData.data = this.encrypt(myReader.result);
		// };
		this.imageData = new FormData();
		this.imageData.append('file', file, file.name);
		// myReader.readAsDataURL(file);
		const allowed_extensions = new Array('png', 'jpeg', 'bmp', 'jpg');
		const file_extension = file.name.split('.').pop().toLowerCase();
		for (let i = 0; i <= allowed_extensions.length; i++) {
			if (allowed_extensions[i] === file_extension) {
				this.imageTypeFlag = false;
				this.imageChangedEvent = event;
				this.modalReference = this.modalService.open(content);
				break;
			} else {
				this.imageTypeFlag = true;
			}
		}
	}
	imageUrlEmpty(e) {
		e.target.value = '';
	}
	cropImg(e: ImageCroppedEvent) {
		this.cropImgPreview = e.base64;
	}

	cancleImage() {
		// this.imageUrl = '';
		this.imageUrl = this.imageUrlCopy ? this.imageUrlCopy : '';
	}
	SaveAndClose() {
		this.profilePreview = true;
		this.preBoardingService.saveImage(this.candidateID, this.imageData).subscribe(response => {
			this.profilePreview = true;
		});
		this.modalReference.close({
			data: this.cropImgPreview
		});
		this.cropSaved = true;
	}
	imageLoaded() {
		this.showCropper = true;
	}

	loadImageFailed() {
		// show message
		console.log('load failed');
	}
	crop(content) {
		this.profilePreview = false;
		this.modalReference = this.modalService.open(content);
	}
	cropperReady(sourceImageDimensions: Dimensions) {
	}

	cropImage(content) {
		this.profilePreview = false;
		// this.modalReference=this.modalService.open(this.el);
		this.modalReference = this.modalService.open(content);
		this.showImageCropper = true;
	}

	saveCroppedImage() {
		this.profilePreview = true;
		this.preBoardingService.saveImage(this.candidateID, this.imageData).subscribe(response => {
			this.profilePreview = true;
		});
		this.dialogRef.close({
			data: this.cropImgPreview
		});
	}
}
