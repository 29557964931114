	<div class="col-12" *ngIf="largeFileSize" [ngClass]="{'no-actions': load}" style="position: relative">
		<div class="error_msg_class" id="error_msg_id"> <img src="../../../../assets/round_cancel_black_24dp.png"
				alt="Error"> The selected file could not be uploaded. Please select the file which is less than 200mb. </div>
	</div>

	<div class="col-12" *ngIf="isDocumentNotSubmitted && documentsSubmittedandApproved" class="all-docs-submitted">
		All the documents have been submitted and approved. You can exit the portal by clicking on the logout button.
	</div>
	<div class="col-12 file-downloader">
	<div class="col-6 file-url" *ngIf="zipFileUrl !== null">
		<h6>Click <a (click)="downloadZip(zipFileUrl)"> <b><u>here</u></b></a> to download the uploaded zip file.</h6>
	</div>
	<div class="col-6 zip_file_class" *ngIf="zipFileUrl !== null">
		<h6> {{zipFileUrlSize }}</h6>
	</div>
</div>
	<div class="col-12" id="shaded_box_id">
		<div class="shaded_box" *ngIf="!documentsSubmittedandApproved">
			<label *ngIf="!filestoUpload" class="choose_file_text" id="choose_file_text" for="file">Choose a zip file to upload</label>
			<label *ngIf="filestoUpload" class="choose_file_text" id="choose_file_text" for="file">Upload the zip file</label>
			<div id="hideFileName">
				<p id="hide_fName_class">File Name: <a (click)="downloadZip(displayFileName)" style="cursor: pointer;">
				<b>{{displayFileName}}</b></a>
				<img *ngIf="displayFileName" id="delete-icon" style="margin-left: 12px;" (click)="removeFile($event)" src="../../../../assets/delete.png" class="cancel-icon" alt="Cancel" />
				</p>
			</div>
		<p *ngIf="!filestoUpload" class="img_container" id="img_container"><img src="../../../../assets/baseline_description_black_24dp.png"
			alt="File Upload"></p>
			<p *ngIf="!filestoUpload" class="btn_container" id="btn_container">
				<input type="file" id="file" accept=".zip" (change)="selectFile($event)" id="file-upload-input" class="select_file_btn"/>
			</p>
			<div *ngIf="specialCharsFound">
				<p style="color: rgb(218, 8, 8); text-align: center;">Upload Files without Speicial Characters</p>
			</div>
			<!-- <div id="progress-bar-div" class="col-11" style="float: left; display: none
			;">
				<mat-progress-bar mode="determinate" value="{{progress1.percentage}}" *ngIf="progressDisplay" class="progressbar"></mat-progress-bar>
	  			<span *ngIf="progressDisplay">{{progress1.percentage}}% completed.</span>
			</div> -->
			<span class="cdk-visually-hidden"  *ngIf="uploadComplete">
				upload complete
			  </span>
			  <div *ngIf="filestoUpload" style="text-align: center;margin-top: 50px;">
				<button mat-raised-button color="primary" id="upload-id-btn" (click)="upload()" [disabled]="uploadComplete">Upload</button>
			</div>
		</div>
	</div>
<div *ngIf="!documentsSubmittedandApproved">
	<p style="color: rgb(218, 8, 8);">Only "Zip" Files are allowed and maximum file size limit is 200MB. Special characters and spaces are not allowed in filename.</p>
</div>
	<div *ngIf="!documentsSubmittedandApproved">
	<div class="col-12 finish-block" *appHasAuthority="'DASHBOARD_VIEW_CANDIDATE'">
		<div class="finish-text">Please review all details before submitting. No changes can be made once submit.</div>
		<button type="submit" *ngIf="!resubmitDocs" mat-raised-button class="submit-button-css" [disabled]= '!uploadComplete' (click)="openModal()">Submit</button>
		<button type="submit" *ngIf="resubmitDocs" mat-raised-button class="submit-button-css" [disabled]= '!resSubmitUpload' (click)="openModal()">Re-Submit</button>
	</div>
	<div class="dark-overlay" *ngIf="load">
		<mat-spinner class="loader"strokeWidth="3" [diameter]="50"> </mat-spinner>
	</div>
</div>
