<div class="row emp-info-row" [ngClass]="{'no-actions': load}" style="position: relative">
	<div class="col-12 layout_class">
		<h3 class="heading_class">Documents</h3>
		<p class="sub_text" *ngIf="employeeType === 'FTE'">Declaration and Nomination Form under the Employees Provident Funds and Employees Pension
			Schemes</p>
		<hr class="employee-info-hr">
	</div>
	<div class="col-12" *ngIf="employeeType === 'FTE'">
		<h5 class="sub_heading_text">EPF Nominee Form , Nominee Form, Gratuity Form , and Police Verification Form</h5>
		<br>
	</div>
	<div class="col-12" *ngIf="employeeType === 'FTE'">
		<div class="shaded_box">
			<div class="col-2 warning_icon">
				<img src="../../../assets/round_warning_black_24dp.png" alt="Warning">IMPORTANT! </div>
			<div class="col-8 export_text">
				<p class="sub_heading_text">Please export the EPF, Nominee Declaration, and police verification form by
					clicking the <strong>Export</strong> button below. Sign the document, scan it and upload it along
					with below mentioned supporting documents in <strong>One ZIP File.</strong></p>
			</div>
			<div class="col-2 export_docs_class">
				<button mat-button class="export_docs" (click)="exportForms()">Export</button>
			</div>
		</div>
	</div>
	<div class="col-12" *ngIf="employeeType !== 'FTE'">
		<div class="shaded_box">
			<div class="col-2 warning_icon">
				<img src="../../../assets/round_warning_black_24dp.png" alt="Warning">IMPORTANT! </div>
			<div class="col-8 export_text">
				<p class="sub_heading_text">Please export the Confidentiality and Information Security Declaration form by
					clicking the <strong>Export</strong> button below. Sign the document, scan it and upload it along
					with below mentioned suppporting documents in <strong>One ZIP File.</strong></p>
			</div>
			<div class="col-2 export_docs_class">
				<button mat-button class="export_docs" (click)="exportForms()">Export</button>
			</div>
		</div>
	</div>
	<div class="col-12" style="padding: 20px;">
		<h5>Suppporting Documents</h5>
		<table id="docs_table" style="width: -webkit-fill-available;">
			<tr>
				<th *ngIf="employeeType==='FTE' || employeeType==='DC' || employeeType==='INTERN' ">Academic Certificates</th>
				<th>Identity Documents</th>
				<th *ngIf="employeeType==='FTE' || employeeType==='DC' || employeeType==='INTERN' ">All Previous Employment Documents</th>
				<th>Others</th>
			</tr>
			<tr class="doc-names-tr">
				<td *ngIf="employeeType==='FTE' || employeeType==='DC' || employeeType==='INTERN' " class="edu-doc-class"  style="text-indent :-0.9em;">
					<div *ngFor="let id of educationalDocuments;let i=index">
						{{i+1}}. {{id.documentName}}
					</div>
				</td>
				<td class="pt-4 id-doc-class" *ngIf="employeeType==='FTE'" style="text-indent :-0.9em;">
					<div *ngFor="let id of identityDocuments;let i=index">
						{{i+1}}. {{id.documentName}}
					</div>
				</td>
				<td class="pt-4 id-doc-dc-class" *ngIf="employeeType==='DC' ">
					<div *ngFor="let id of identityDocuments;let i=index">
						{{i+1}}. {{id.documentName}}
					</div>
				</td>
				<td class="pt-4 id-doc-dc-class" *ngIf="employeeType==='INTERN'">
					<div *ngFor="let id of identityDocuments;let i=index">
						{{i+1}}. {{id.documentName}}
					</div>
				</td>
				<td class="pt-4 id-doc-vc-class" *ngIf="employeeType==='VC'">
					<div *ngFor="let id of identityDocuments;let i=index">
						{{i+1}}. {{id.documentName}}
					</div>
				</td>
				<td *ngIf="employeeType==='FTE'" class="pt-4 emp-doc-class" style="text-indent :-0.9em;">
					<div *ngFor="let id of employmentDocuments;let i=index">
						{{i+1}}. {{id.documentName}}
					</div>
				</td>
				<td *ngIf="employeeType==='DC'" class="pt-4 emp-doc-dc-class" style="text-indent :-0.9em;">
					<div *ngFor="let id of employmentDocuments;let i=index">
						{{i+1}}. {{id.documentName}}
					</div>
				</td>
				<td *ngIf="employeeType==='INTERN'" class="pt-4 emp-doc-dc-class" style="text-indent :-0.9em;">
					<div *ngFor="let id of employmentDocuments;let i=index">
						{{i+1}}. {{id.documentName}}
					</div>
				</td>
				<td *ngIf="employeeType==='VC'" class="pt-4 emp-doc-vc-class" style="text-indent :-0.9em;">
					<div *ngFor="let id of employmentDocuments;let i=index">
						{{i+1}}. {{id.documentName}}
					</div>
				</td>
				<td class="pt-4 other-doc-class" *ngIf="employeeType==='FTE'">
					<div *ngFor="let id of otherDocuments;let i=index">
						{{i+1}}. {{id.documentName}}
					</div>
				</td>
				<td class="pt-4 other-doc-dc-class" *ngIf="employeeType==='DC'">
					<div *ngFor="let id of otherDocuments;let i=index">
						{{i+1}}. {{id.documentName}}
					</div>
				</td>
				<td class="pt-4 other-doc-dc-class" *ngIf="employeeType==='INTERN'">
					<div *ngFor="let id of otherDocuments;let i=index">
						{{i+1}}. {{id.documentName}}
					</div>
				</td>
				<td class="pt-4 other-doc-vc-class" *ngIf="employeeType==='VC'">
					<div *ngFor="let id of otherDocuments;let i=index">
						{{i+1}}. {{id.documentName}}
					</div>
				</td>
			</tr>
		</table>
				<div class="col-12 document-row">
				<div class="col-12">
					<app-file-uploader (docStatusChange)="statusUpdate($event)" (filesSent)="selectedFilesSent($event)"
						></app-file-uploader>
				</div>
			</div>
		<mat-spinner *ngIf="load" class="loader"strokeWidth="3" [diameter]="50"> </mat-spinner>
</div>
