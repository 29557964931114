import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { PreBoardingService } from 'src/app/shared/pre-boarding.service';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import {
    MatDialog,
} from '@angular/material/dialog';
// import { FileUploaderComponent } from './file-uploader/file-uploader.component';

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
    candidateID: any;
    documentList: any;
    load: boolean = false;
    @ViewChild('myModal') myModal;
    private modalRef;
    documentsEmptyorNot: any;
	selectFiles: any;
    isExportClicked: boolean;
    employeeType: string;
    @Output() isDocumentNotSubmitted=new EventEmitter<boolean>();
    @Output() uploadComplete=new EventEmitter<boolean>();
    identityDocuments: any;
    educationalDocuments: any;
    employmentDocuments: any;
    otherDocuments: any;

    constructor(
        private preBoardingService: PreBoardingService,
        private _router: Router,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
       // this.load = true,
        this.employeeType=this.preBoardingService.employeeType;
        console.log('this.emptype', this.employeeType);
        this.isExportClicked=false;
        this.isDocumentNotSubmitted.emit(false);
        this.uploadComplete.emit(false);
        this.candidateID = sessionStorage.getItem('candidateID');
        if (sessionStorage.getItem('candidateID')) {
            this.preBoardingService.retrieveDocuments(this.candidateID)
                .subscribe((response) => {
            });
        }
        this.preBoardingService.retrieveDocumentCheckList(this.employeeType).subscribe(res => {
            this.identityDocuments=res.documentCheckList.IDENTITY_DOCUMENTS;
            this.educationalDocuments=res.documentCheckList.EDUCATIONAL_DOCUMENTS;
            this.employmentDocuments=res.documentCheckList.EMPLOYMENT_DOCUMENTS;
            this.otherDocuments=res.documentCheckList.OTHERS;
        });
        this.preBoardingService.candidateProgressStatus(this.candidateID).subscribe(res => {
            if (this.employeeType==='DC') {
                this.preBoardingService.getDCProgress(res);
            } else if (this.employeeType==='INTERN') {
                this.preBoardingService.getINTERNProgress(res);
            }  else if (this.employeeType==='FTE') {
                this.preBoardingService.getProgress(res);
            } else if (this.employeeType==='VC') {
                this.preBoardingService.getVCProgress(res);
            }
        });
    }
    statusUpdate(event) {
        this.ngOnInit();
    }
	selectedFilesSent(event){
        this.isExportClicked = true;
		this.selectFiles = event;
	}
	export(formType) {
        switch (formType) {
            case 'epf': {
                this.preBoardingService
                    .exportEpfForm(this.candidateID, 'epf')
                    .subscribe((data) => {
                        saveAs(data, 'EPF Form'.replace(/\s+/g, ''));
                    });
                break;
            }
            case 'nominee': {
                this.preBoardingService
                    .exportEpfForm(this.candidateID, 'nominee')
                    .subscribe((data) => {
                        saveAs(data, 'Nominee Form'.replace(/\s+/g, ''));
                    });
                break;
            }
            case 'nomineDec': {
                this.preBoardingService
                    .exportEpfForm(this.candidateID, 'nomineDec')
                    .subscribe((data) => {
                        saveAs(data, 'Nominee Declaration'.replace(/\s+/g, ''));
                    });
                break;
            }
            case 'policeVer': {
                this.preBoardingService
                    .exportEpfForm(this.candidateID, 'policeVer')
                    .subscribe((data) => {
                        saveAs(data, 'Police Verification'.replace(/\s+/g, ''));
                    });
                break;
            }
            case 'generalDoc1': {
                this.preBoardingService.getGeneralDocuments('Code_of_Business_Conduct _Ethics_Policy.pdf').subscribe((data) => {
                    saveAs(data, 'Code_of_Business_Conduct _Ethics_Policy.pdf'.replace(/\s+/g, ''));
                });
                break;
            }
            case 'generalDoc2': {
                this.preBoardingService.getGeneralDocuments('Confidentiality_and_Information_Security_Declaration.pdf').subscribe((data) => {
                    saveAs(data, 'Confidentiality_and_Information_Security_Declaration.pdf'.replace(/\s+/g, ''));
                });
            }
        }
    }

    exportForms () {
        if (this.employeeType==='FTE') {
        this.export('epf');
		this.export('nominee');
		this.export('nomineDec');
        this.export('policeVer');
        this.export('generalDoc1');
        this.export('generalDoc2');
        } else if (this.employeeType!=='FTE') {
            this.export('generalDoc1');
            this.export('generalDoc2');
        }
	}
        testHRDocsButton(){
            this._router.navigateByUrl('/HR-docs');
        }
}


