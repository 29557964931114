<div class="row">
	<div class="col-12">
		<p><b>Please select the documents to be rejected.</b> <span><img src="../../../assets/icon-close.png"
					class="close-button" (click)="onNoClick()" style="height: 19px;"></span> </p>
	</div>
	<div class="col-12">
		<mat-accordion class="example-headers-align">
			<mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
				<mat-expansion-panel-header>
					<mat-panel-title>
						<strong> Document Check List</strong>
					</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="col-12">
					<p style="margin-bottom: 10px; margin-left: -14px;">
						<mat-checkbox class="example-margin" [checked]="checkedVariable" (change)="conditionalApproveChecked($event)">Conditional Approve</mat-checkbox>
					</p>
					<span class="col-3" style="margin-bottom: 0;float: right;"><b>Remarks</b></span>
				</div>
				<div class="col-12">
					<mat-checkbox class="example-margin" [checked]="allComplete"
						[indeterminate]="someCompleteIdentity()" (change)="setAllIdentity($event.checked)"
						*ngIf="showidentiydocuments">Identity Documents</mat-checkbox>
						<mat-checkbox class="example-margin" [checked]="allComplete"
						[indeterminate]="someCompleteIdentity()" (change)="setAllIdentity($event.checked)"
						*ngIf="rejectList.length===0">Identity Documents</mat-checkbox>
					<span class="example-list-section">
						<ul class="ul-width">
							<li class="rejected-array-tr" *ngFor="let subtask of identityDocuments;let i=index">
								<span *ngIf="rejectList.indexOf(subtask.name) > -1">
									<mat-checkbox [(ngModel)]="subtask.completed" name="{{subtask.name}}"
										(change)="updateAllIdentityComplete($event,i,subtask.name,'cb')">
										{{subtask.name}}
									</mat-checkbox>
									<span class="li-width">
										<input class="form-control"
											(change)="updateAllIdentityComplete($event,i,subtask.name,'ip')" type="text"
											name="{{subtask.name}}" ngDefaultControl
											[value]="subtask.docName ? subtask.docName: ''">
									</span>
								</span>
								<span *ngIf="rejectList.length===0">
									<mat-checkbox [(ngModel)]="subtask.completed" name="{{subtask.name}}"
										(change)="updateAllIdentityComplete($event,i,subtask.name,'cb')">
										{{subtask.name}}
									</mat-checkbox>
									<span class="li-width">
										<input class="form-control"
											(change)="updateAllIdentityComplete($event,i,subtask.name,'ip')" type="text"
											name="{{subtask.name}}" ngDefaultControl
											[value]="subtask.docName ? subtask.docName: ''">
									</span>
								</span>
							</li>
							<!-- </div> -->
						</ul>
					</span>
				</div>
				<div class="col-12">
					<mat-checkbox class="example-margin" [checked]="allEducationalComplete"
						[indeterminate]="someCompleteEducational()" (change)="setAllEducational($event.checked)"
						*ngIf="showeducationaldocuments && rejectEmployeeType !='VC'">
						Educational Documents</mat-checkbox>
						<mat-checkbox class="example-margin" [checked]="allEducationalComplete"
						[indeterminate]="someCompleteEducational()" (change)="setAllEducational($event.checked)"
						*ngIf="rejectList.length===0 && rejectEmployeeType !='VC'">
						Educational Documents</mat-checkbox>
					<span class="example-list-section">
						<ul class="ul-width">
							<li class="rejected-array-tr" *ngFor="let subtask of educationalDocuments;let i=index">
								<span *ngIf="rejectList.indexOf(subtask.name) > -1">
									<mat-checkbox [(ngModel)]="subtask.completed" name="{{subtask.name}}"
										(change)="updateAllEducationalComplete($event,i,subtask.name,'cb')">
										{{subtask.name}}
									</mat-checkbox>
									<span class="li-width"><input type="text" class="form-control"
											(change)="updateAllEducationalComplete($event,i,subtask.name,'ip')"
											maxlength="299" ngDefaultControl name="{{subtask.name}}"
											[value]="subtask.remarks ? subtask.remarks : ''"></span>
								</span>
								<span *ngIf="rejectList.length===0">
									<mat-checkbox [(ngModel)]="subtask.completed" name="{{subtask.name}}"
										(change)="updateAllEducationalComplete($event,i,subtask.name,'cb')">
										{{subtask.name}}
									</mat-checkbox>
									<span class="li-width"><input type="text" class="form-control"
											(change)="updateAllEducationalComplete($event,i,subtask.name,'ip')"
											maxlength="299" ngDefaultControl name="{{subtask.name}}"
											[value]="subtask.remarks ? subtask.remarks : ''"></span>
								</span>
							</li>
						</ul>
					</span>
				</div>
				<div class="col-12">
					<mat-checkbox class="example-margin" [checked]="allEmploymentComplete"
						[indeterminate]="someCompleteEmployment()" (change)="setAllEmployment($event.checked)"
						*ngIf="showemploymentdocuments && rejectEmployeeType !='VC'">
						Employment Documents</mat-checkbox>
						<mat-checkbox class="example-margin" [checked]="allEmploymentComplete"
						[indeterminate]="someCompleteEmployment()" (change)="setAllEmployment($event.checked)"
						*ngIf="rejectList.length===0 && rejectEmployeeType !='VC'">
						Employment Documents</mat-checkbox>
					<span class="example-list-section">
						<ul class="ul-width">
							<li class="rejected-array-tr" *ngFor="let subtask of employmentDocuments;let i=index">
								<span *ngIf="rejectList.indexOf(subtask.name) > -1">
									<mat-checkbox [(ngModel)]="subtask.completed" name="{{subtask.name}}"
										(change)="updateAllEmploymentComplete($event,i,subtask.name,'cb')">
										{{subtask.name}}
									</mat-checkbox>
									<span class="li-width"><input type="text" class="form-control"
											(change)="updateAllEmploymentComplete($event,i,subtask.name,'ip')"
											maxlength="299" ngDefaultControl name="{{subtask.name}}"
											[value]="subtask.remarks ? subtask.remarks : ''">
									</span>
								</span>
								<span *ngIf="rejectList.length===0">
									<mat-checkbox [(ngModel)]="subtask.completed" name="{{subtask.name}}"
										(change)="updateAllEmploymentComplete($event,i,subtask.name,'cb')">
										{{subtask.name}}
									</mat-checkbox>
									<span class="li-width"><input type="text" class="form-control"
											(change)="updateAllEmploymentComplete($event,i,subtask.name,'ip')"
											maxlength="299" ngDefaultControl name="{{subtask.name}}"
											[value]="subtask.remarks ? subtask.remarks : ''">
									</span>
								</span>
							</li>
						</ul>
					</span>
				</div>
				<div class="col-12">
					<mat-checkbox class="example-margin" [checked]="allOtherComplete" [indeterminate]="someCompleteOthers()"
						(change)="setAllOthers($event.checked)" *ngIf="showotherdocuments">Other Documents
					</mat-checkbox>
					<mat-checkbox class="example-margin" [checked]="allOtherComplete" [indeterminate]="someCompleteOthers()"
						(change)="setAllOthers($event.checked)" *ngIf="rejectList.length===0">Other Documents
					</mat-checkbox>
					<span class="example-list-section">
						<ul class="ul-width">
							<li class="rejected-array-tr" *ngFor="let subtask of otherDocuments;let i=index">
								<span *ngIf="rejectList.indexOf(subtask.name) > -1">


									<mat-checkbox [(ngModel)]="subtask.completed" name="{{subtask.name}}"
										(change)="updateAllOthersComplete($event,i,subtask.name,'cb')">
										{{subtask.name}}
									</mat-checkbox>
									<span class="li-width"><input type="text" class="form-control"
											(change)="updateAllOthersComplete($event,i,subtask.name,'ip')"
											ngDefaultControl name="{{subtask.name}}"
											[value]="subtask.remarks ? subtask.remarks : ''"></span>
								</span>
								<span *ngIf="rejectList.length===0">


									<mat-checkbox [(ngModel)]="subtask.completed" name="{{subtask.name}}"
										(change)="updateAllOthersComplete($event,i,subtask.name,'cb')">
										{{subtask.name}}
									</mat-checkbox>
									<span class="li-width"><input type="text" class="form-control"
											(change)="updateAllOthersComplete($event,i,subtask.name,'ip')"
											ngDefaultControl name="{{subtask.name}}"
											[value]="subtask.remarks ? subtask.remarks : ''"></span>
								</span>
							</li>
						</ul>
					</span>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</div>
<div>
	<br>
	<div *ngIf="largeFileSize">
		<div class="error_msg_class" id="error_msg_id"> <img src="../../../../assets/round_cancel_black_24dp.png"
				alt="Error"> The selected file could not be uploaded. Please select the file which is less than 200mb.
		</div>
	</div>
	<div class="col-12">
		<div class="shaded_box">
			<label *ngIf="!filestoUpload" class="choose_file_text" id="choose_file_text" for="file">Choose a zip file to
				upload</label>
			<label *ngIf="filestoUpload" class="choose_file_text" id="choose_file_text" for="file">Upload the zip
				file</label>
			<div id="hideFileNames">
				<p id="hide_fName_class" style="text-align: center;">File Name: <a style="cursor: pointer;">
						<b>{{displayFileName}}</b></a>
					<img *ngIf="displayFileName" id="delete-icon" style="margin-left: 12px;"
						(click)="removeFile($event)" src="../../../../assets/delete.png" class="cancel-icon"
						alt="Cancel" /></p>
			</div>

			<p *ngIf="!filestoUpload" class="img_container" id="img_container"><img
					src="../../../../assets/baseline_description_black_24dp.png" alt="File Upload"></p>
			<p *ngIf="!filestoUpload" class="btn_container" id="btn_container">
				<input type="file" id="file" accept=".zip" (change)="handleFileInput($event.target.files)"
					id="file-upload-input" class="select_file_btn" />
			</p>
			<span class="cdk-visually-hidden" *ngIf="uploadComplete">
				upload complete
			</span>
			<div *ngIf="filestoUpload" style="text-align: center;">
				<button mat-raised-button color="primary" id="upload-id-btn" (click)="uploadFileToActivity()"
					[disabled]="uploadComplete">Upload</button>
			</div>
			<div *ngIf="specialCharsFound">
				<p style="color: rgb(218, 8, 8); text-align: center;">Upload Files without Speicial Characters</p>
			</div>
		</div>
	</div>
	<div>
		<p style="color: rgb(218, 8, 8);">Only "Zip" Files are allowed and maximum file size limit is 200MB. Special characters and spaces are not allowed in filename.</p>
	</div>
</div>
<br>
<div class="col-12 success-message" *ngIf="uploadComplete">
	<p>The file has been uploaded successfully!</p>
</div>
<div class="col-12" style="margin-top: 20px;text-align: center;">
	<button type="button" class="submit-reject test" (click)="submitRejectedDocs()"
		mat-raised-button [disabled]="!uploadComplete">Submit</button>
	<button type="button" style="margin-left: 42px;" (click)="rejectDialogclose()" class="submit-reject"
		mat-raised-button>Cancel</button>
</div>
<div class="dark-overlay" *ngIf="load">
<mat-spinner class="loader" strokeWidth="3" [diameter]="50"> </mat-spinner>
</div>