<div class="row">
    <div class="col-6">
        <p style="text-align: center;">
            <img *ngIf="!cropImgPreview" class="img-responsive" src="../../../../assets/User_Profile_Pic.png" height="180" />
            <span *ngIf="cropImgPreview">
                 <img [src]="cropImgPreview"  style="width: 180px; border-radius: 50%;" />
            </span>
        </p>
        </div>
        <div class="col-6">
                <label>
                    <input type="file" accept=".png,.jpeg,.bmp,.jpg"
                        (change)="fileChangeEvent($event,content)"
                        (click)="imageUrlEmpty($event)" />
                </label>
       
            <button *ngIf="cropSaved" style="background-color: #0eb3f7; color: aliceblue;" mat-raised-button class="submit-button-css" (click)="cropImage(content)" >Crop</button>
            <button  *ngIf="cropSaved" style="background-color: #0eb3f7;color: aliceblue;" mat-raised-button class="submit-button-css" (click)="saveCroppedImage()">Save</button>
    </div>
    <div class="col-12 profile-disclaimer">*Upload Files without Speicial Characters</div>
    <div class="col-12 profile-disclaimer">*maximum file size to import : 200kb</div>
    <!-- <p *ngIf="imageData">This is GET call working</p> -->
    <!-- <button *ngIf="profilePreview" class="btn btn-sm btn-primary" (click)="crop(content)">Crop</button> -->
</div>
<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Crop Image</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click');cancleImage();">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4" [resizeToWidth]="400" format="png" (imageCropped)="cropImg($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="SaveAndClose()">Save</button>
    </div>
</ng-template>
<ng-template *ngIf="showImageCropper" #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Crop Image</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click');cancleImage();">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4" [resizeToWidth]="400" format="png" (imageCropped)="cropImg($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="SaveAndClose()">Save</button>
    </div>
</ng-template>